<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { VueTelInput } from 'vue-tel-input';
import vue2Dropzone from "vue2-dropzone";
import AvatarCropper from "vue-avatar-cropper";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Edit Employee",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    vueDropzone: vue2Dropzone,AvatarCropper,
    VueTelInput
    
  },

  data() {
    return {
      title: 'Edit Employee',
      items: null,
      showCropper: false,
      processing_image: false,
      show: true,
      uploadURL: this.$axios.defaults.baseURL + "/my-profile-image",
      areas: [],
      locations: [],
      teamMember: {
        name: null,
        email: null,
        phone: null,
        status: null,
        employee: {
          experience_level:null,
          payment : null,
          employee_type: null,
          area_roles: [],
        },
      },
      isHidden: false,
      isShow: false,
      processing: false,
      user: {
        name: null,
        email: null,
      },
        old_password: null,
        new_password: null,
        confirm_password: null,
      submitted: false,
      work_area: [],
      under_eighteen_docs: null,
      work_area_role: null,
      workArea: [],
      dropzoneOptions: {
        url: this.$axios.defaults.baseURL + "/store-file",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
        addRemoveLinks: true,
      },
      phoneError:true,
      step: null,
      availibilties: [],
      payment: null,
      selected_payment : null,
    };
  },
  validations() {
    if(this.step == 0)
    {
      return {
        teamMember:{
          name : {required},
          phone : {required},
          employee:{
            experience_level : { required},
            employee_type: { required},
            locations: { required},
            area_roles : { required},
          }
        },
        work_area : {required},
      }
    }else if(this.step == 1){
      return {
        old_password : {required},
        new_password : {required},
        confirm_password : {required},
      }
    }else {
      return {}
    }
  },
  watch: {
    'teamMember.phone' : {
      handler : function(newVal , oldVal)
      {
        if(newVal == "")
        {
          this.phoneError = false;
        }
      }
    },
    'teamMember.type' : {
      handler : function(newVal,oldVal) {
        if(this.teamMember.type == 'manager')
        {
          this.teamMember.employee.employee_type = 'Manager';
        }
      }
    },
  },
  created() {
    this.fetchLocations();
  },

  mounted() {
    this.fetchTeamMember();
    this.getAvailiabilties();
    document.body.classList.add("authentication-bg");
  },

  methods: {
    fetchTeamMember() {
      let self = this;
      this.$axios
        .get("/employee/team-member/" + this.$route.params.user_id)
        .then((response) => {
          let data = response.data.payload;
          self.teamMember = data;
          this.teamMember.employee.experience_level = this.teamMember.employee.experience_level.split(/(\d+)/)[1];
          data.employee.area_roles.forEach(( workarea_name)=>{ 
          let found =  self.work_area.findIndex(area => area.id == workarea_name.area.id);
          if(found  < 0){
            self.work_area.push(workarea_name.area);
            }
          });
          if(self.teamMember.employee.payment) this.selected_payment = 'base_salary';
          self.isHidden = data.employee.minor == 1 ? true : false;
          self.isShow =
          data.employee.under_eighteen_docs == null ? false : true;
          self.getSelectedLocationAreas();
          self.getSelectedWorkAreasRole();
        }).catch(error => {
        this.handleErrorResponse(error.response, "error");
        });
    },
    getAvailiabilties()
    {
      this.$axios.get('get-static-data' , {params: {types: JSON.stringify(["availiabilty"]) }}).then((response) => {
        let data = response.data.payload.data;
        this.availibilties = data.availiabilty;
      }).catch((error) => {
      this.handleErrorResponse(error.response, "error");
      });
    },
    tryToUpdate() {
      let self = this;
      if (self.processing) {
        return;
      }
      self.submitted = true;
      self.$v.$touch();

      if (self.$v.$invalid) {
        return;
      } 
      if(this.phoneError == false)
      {
        return;
      }
      
      if(this.selected_payment == null) {
        this.teamMember.employee.payment = null;
      }
      
      self.processing = true;
      self.$axios
        .post("/employee/team-member/update", {
          id: self.teamMember.id,
          employee_id: self.teamMember.employee.id,
          name: self.teamMember.name,
          email: self.teamMember.email,
          avatar: self.teamMember.avatar,
          experience_level: self.teamMember.employee.experience_level,
          action_detail: self.action_detail,
          phone: self.teamMember.phone,
          emp_job_type: self.teamMember.employee.employee_type,
          minor: self.isHidden,
          payment: self.teamMember.employee.payment,
          under_eighteen_docs: self.under_eighteen_docs ?? this.teamMember.employee.under_eighteen_docs,
          work_area: self.work_area.map((item) => {
            return item.name
          }),
          location_id: self.teamMember.employee.locations.map((item) => {
            return item.id;
          }),
          status: self.teamMember.employee.status,
          work_area_role_id: self.teamMember.employee.area_roles.map((item) => {
            return item.id;
          }),
        })
        .then((response) => {
          self.triggerSwal('Updated Successfully!', "success");
          self.$router.push("/team-member-record");
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(()=> {
          self.processing = false;
        });
    },
    checkPhone(phone)
    {
      if(this.teamMember.phone)
      this.phoneError = phone.valid;
    },
    updatePassword(){
       let self = this;
      if(self.new_password!=self.confirm_password){
        self.triggerSwal('Confrim Password Not Match', "error");
        return;
      }
      if (self.processing) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      self.processing = true;

      self.$axios
        .post("/user/update-password/"+self.teamMember.id, {
          id: self.teamMember.id,
          new_password: self.new_password,
          old_password: self.old_password,
          
        })
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          self.$router.push("/team-member-record");
        })
        .catch((error) => {
           this.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },

    fileUploaded(file) {
      this.under_eighteen_docs = file.xhr.response;
    },

    fetchLocations() {
      this.$axios.get("/location/index").then((response) => {
        let data = response.data.payload;
        this.locations = data;
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      }).finally(function () {
      });
    },
    getSelectedLocationAreas()
    {
      this.locations.forEach((location) => {
        this.teamMember.employee.locations.forEach(emp_loc => {   
          if (emp_loc.id == location.id) {
              this.areas.push(...location.areas)
            }
        });
      });
    },
    fetchArea(e) {
        this.areas.push(...e.areas)
    },
    
    removeArea(e){
      this.areas = this.areas.filter((item) => (item.location_id != e.id));
      this.work_area = this.work_area.filter((item) => {
        this.teamMember.employee.area_roles = this.teamMember.employee.area_roles.filter((ele) => (ele.area_id != item.id)); //deselect the selected roles.
        this.workArea = this.workArea.filter((ele) => (ele.area_id != item.id)); //remove roles from dropdown without selecting a workarea.
        item.location_id != e.id
      });
    },
    getSelectedWorkAreasRole()
    {
      this.areas.forEach((area) => {
        this.work_area.forEach(emp_work_area => {   
          if (emp_work_area.id == area.id) {
              this.workArea.push(...area.area_role)
            }
        });
      });
    },
    showRole(e) {
      this.workArea.push(...e.area_role);
    },
    removeRole(e){
      this.workArea = this.workArea.filter((item) => (item.area_id != e.id));
    },

    handleUploading(form, xhr) {
      this.processing_image = true;
    },
    handleUploaded(response) {
      this.processing_image = false;

      if (response) {
        this.teamMember.avatar = response;
        this.triggerSwal("Image Uploaded Successfully!", "success");
      }
    },
    handleCompleted(response, form, xhr) {
      this.processing_image = false;
    },
    handlerError(message, type, xhr) {
      this.processing_image = false;
      this.triggerSwal(
        "There is a problem in updating your profile image.","error");
    },
    numberFormat(e)
    {
      return this.teamMember.employee.experience_level = e.substring(0,2);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="mb-0 tabs-pills">
        <b-tabs pills content-class="text-muted"  v-model="step">
          <b-tab active  >
            <template v-slot:title >
              <span class="">My Profile</span>
            </template>
            <div class="card" v-if="step==0">
              <div class="card-body">
                <div class="my-form">
                  <div class="d-flex flex-column-reverse flex-lg-row flex-md-row flex-xl-row pt-2 row">
                    <div class="col-md-7 disp-flex align__items__center mt-2 mb-2">
                      <div>
                        <div class="card vue-avatar-cropper-demo text-center">
                          <div class="card-body">
                            <img
                              :src="teamMember.avatar==null ? teamMember.avatar_path : assets_url + teamMember.avatar"
                              class="card-img avatar"
                            />
                          </div>

                          <avatar-cropper
                            @uploading="handleUploading"
                            @uploaded="handleUploaded"
                            :toBlob="true"
                            @completed="handleCompleted"
                            :labels="{ submit: 'Upload', cancel: 'Cancel' }"
                            @error="handlerError"
                            trigger="#pick-avatar"
                            :upload-url="uploadURL"
                          />
                          <button class="btn btn-primary btn-sm" id="pick-avatar" >
                            <span v-if="teamMember.avatar==null"> Select a new image </span>
                          </button>
                        </div>
                      </div>
                      <div class="ml-2" v-if="processing_image">
                        <span class="spinner-grow spinner-grow-sm"></span>
                        <span>Profile Image is Uploading ...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <b-form id="profile_update" @submit.prevent="tryToUpdate">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          label="Full Name"
                          label-for="formrow-fullname-input"
                          class="mb-3"
                        >
                          <b-form-input
                            type="text"
                            id="formrow-fullname-input"
                            v-model="teamMember.name"
                            placeholder="Enter full name"
                            :class="{
                              'is-invalid': submitted && $v.teamMember.name.$error,
                            }"
                          ></b-form-input>
                          <div
                            v-if="submitted && !$v.teamMember.name.required"
                            class="invalid-feedback"
                          >
                            Name is required.
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                          id="fullname-group"
                          label="Email"
                          label-for="email"
                          class="mb-3"
                        >
                          <b-form-input
                            id="email"
                            v-model="teamMember.email"
                            type="email"
                            placeholder="Enter email"
                            readonly
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label>Availability</label>
                        <b-input-group >
                          <select v-model="teamMember.employee.employee_type"  id="emp_job_type" class="form-select" 
                                :class="{'is-invalid': submitted && $v.teamMember.employee.employee_type.$error}" :disabled="teamMember.type == 'manager'">
                            <option :value="i" v-for="(availibilty, i) in availibilties" :key="i">{{availibilty}}</option>
                          </select>
                          <b-input-group-prepend is-text class="w-25">
                                <b-form-checkbox  value='manager' v-model="teamMember.type" checked plain >Manager</b-form-checkbox>
                          </b-input-group-prepend>
                          <div
                              v-if="submitted && !$v.teamMember.employee.employee_type.required"
                              class="invalid-feedback"
                              >
                              Availibilty is required.
                          </div>
                        </b-input-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                          id="example-tel"
                          label="Phone Number"
                          label-for="tele"
                          class="mb-3"
                        >
                          <vue-tel-input v-model="teamMember.phone" :autoFormat="true" 
                              :class="{
                                'is-invalid': submitted && $v.teamMember.phone.$error,
                              }"
                              :dropdownOptions="{showFlags:true, showSearchBox:true}"  
                              :mode="'international'" @validate="checkPhone($event)" :validCharactersOnly="true"
                              ></vue-tel-input>
                              <div  v-if="!phoneError"
                                class="text-danger mt-1"
                              >
                                Invalid Phone Number.
                              </div>
                            <div
                              v-if="submitted && !$v.teamMember.phone.required"
                              class="invalid-feedback"
                            >
                              Phone is required.
                            </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          id="example-tel"
                          label="Experience"
                          label-for="experience-level"
                          class="mb-3"
                        >
                          <b-form-input :formatter="numberFormat" type="number" placeholder="Enter Experience"  min="1"
                            v-model="teamMember.employee.experience_level" :class="{
                              'is-invalid': submitted && $v.teamMember.employee.experience_level.$error,
                            }"></b-form-input>

                          <div
                            v-if="submitted && !$v.teamMember.employee.experience_level.required"
                            class="invalid-feedback"
                          >
                            Experience is required.
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <label>Select Location</label>
                        <multiselect
                          v-model="teamMember.employee.locations"
                          :options="locations"
                          label="name"
                          track-by="id"
                          :multiple="true"
                          @select="fetchArea($event)"
                          @remove="removeArea($event)"
                          :class="{
                              'is-invalid': submitted && $v.teamMember.employee.locations.$error,
                            }"
                        ></multiselect>
                        <div
                          v-if="submitted && !$v.teamMember.employee.locations.required"
                          class="invalid-feedback"
                        >
                          Location is required.
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label>Work Area</label>
                        <div class="">
                            <multiselect
                              :class="{'is-invalid': submitted && $v.work_area.$error}" 
                              v-model="work_area"
                              :options="areas"
                              label="name"
                              track-by="id"
                              :multiple="true"
                              @select="showRole($event)"
                              @remove="removeRole($event)"
                            ></multiselect>
                            <div
                              v-if="submitted && !$v.work_area.required"
                              class="invalid-feedback"
                            >
                              Work Area is required.
                            </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label>Select Role</label>
                        <multiselect
                          v-model="teamMember.employee.area_roles"
                          :options="workArea"
                          label="name"
                          track-by="id"
                          :multiple="true"
                          :class="{'is-invalid': submitted && $v.teamMember.employee.area_roles.$error}"
                        ></multiselect>
                        <div
                          v-if="submitted && !$v.teamMember.employee.area_roles.required"
                          class="invalid-feedback"
                        >
                          Role is required.
                        </div>
                        <span class="span">You can select multiple roles</span>
                      </div>
                    </div>

                      <div>
                        <div class="mb-3">
                            <p>Select Salary Option</p>
                            <select class="form-select" v-model="selected_payment">
                              <option value="base_salary">Base salery per hour</option>
                              <option :value="null">Role salary per hour</option>
                            </select>
                          </div>

                          <div  v-if="selected_payment== 'base_salary'">
                              <div class="input-group">
                                  <span class="input-group-text">$</span>
                                  <input type="number"  required placeholder="Enter hourly payment" v-model="teamMember.employee.payment"  class="form-control" />
                              </div>
                          </div>
                      </div>
                  
                  <div v-if="teamMember.type != 'manager'" class="d-none">
                    <div class="row pt-3">
                      <div class="col-md-12">
                        <h6>Please answer these following question.</h6>
                        <p class="note primaryColor roboto">
                          NOTE: Please wait for approval, you will receive notification
                          on your Email/Text.
                        </p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <label>Are you under the age of 18?</label>
                        <div class="group-button d-flex gap-2">
                          <button
                            type="button"
                            class="m-0 rounded-pill px-4 py-2 fw-bold"
                            :class="
                              isHidden == true
                                ? 'register-btn-active text-white'
                                : 'outline-button'
                            "
                            @click="isHidden = true"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            class="m-0 rounded-pill px-4 py-2 fw-bold"
                            :class="
                              isHidden == false
                                ? 'register-btn-active text-white'
                                : 'outline-button'
                            "
                            @click="isHidden = false"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" v-show="isHidden">
                      <div class="col-md-12">
                        <label
                          >Do you have a work authorization that allows you to work
                          during regular school hours & eliminates the weekly hour
                          restrictions as set by the State of Florida?
                        </label>
                        <div class="d-flex gap-2 align-items-center">
                          <button
                            type="button"
                            class="m-0 rounded-pill px-4 py-2 fw-bold"
                            :class="
                              isShow == true
                                ? 'register-btn-active text-white'
                                : 'outline-button'
                            "
                            @click="isShow = true"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            class="m-0 rounded-pill px-4 py-2 fw-bold"
                            :class="
                              isShow == false
                                ? 'register-btn-active text-white'
                                : 'outline-button'
                            "
                            @click="isShow = false"
                          >
                            No
                          </button>
                        </div>
                        <div
                          class="
                            col-md-2
                            ms-1
                            d-flex
                            flex-column
                            align-items-center
                            justify-content-center
                            mt-3
                            radius-10
                          "
                          style="height: 75px; border: 1px solid #eee"
                        >
                          <div>
                            <i
                              v-b-modal.modal-minor
                              :class="{
                                'fas fa-file-alt': teamMember.employee.minor == 1,
                              }"
                            ></i>
                          </div>
                          <div class="f-12">Old File</div>
                        </div>

                        <vue-dropzone
                          v-show="isShow"
                          class="w-25 mt-3"
                          id="dropzone"
                          ref="myVueDropzone"
                          :use-custom-slot="true"
                          :options="dropzoneOptions"
                          @vdropzone-success="fileUploaded"
                        >
                          <div class="dropzone-custom-content">
                            <p class="mb-0 pb-0 f-12">
                              <img
                                src="@/assets/images/upload-file.svg"
                                class="mb-0 pb-0"
                                alt=""
                              />
                              Please Upload a copy of work authorization.
                            </p>
                          </div>
                        </vue-dropzone>
                      </div>
                    </div>
                  </div>
                    <div class="mt-3 col-md-12">
                      <SubmitButton
                        :processing="processing"
                        text="Update"
                        @clicked="tryToUpdate"
                      />
                    </div>
                  </b-form>
                </div>
              </div>
              <!-- end card-body -->

              <!-- end card -->
            </div>
          </b-tab>

          <b-tab >
            <template v-slot:title>
              <span class="">Change Password</span>
            </template>
            <div class="card mt-3" v-if="step==1">
              <div class="card-body">
                <form class="form-horizontal" role="form">
                  <div class="row justify-content-md-center">
                      <div class="col-md-4">
                        <b-form-group
                          id="old_password-group"
                          label="Old Password"
                          class="mb-3"
                          label-for="old_password"
                        >
                          <b-form-input
                            id="old_password"
                            v-model="old_password"
                            :class="{'is-invalid':$v.old_password.$error}"
                            type="password"
                            placeholder="Enter password"
                            required
                          >
                          </b-form-input>
                          <div v-if="$v.old_password.$error" class="invalid-feedback" >
                              <span v-if="!$v.old_password.required">Old Password is required.</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-form-group
                          id="password-group"
                          label="New Password"
                          class="mb-3"
                          label-for="password"
                        >
                          <b-form-input
                            id="password"
                            v-model="new_password"
                            type="password"
                            placeholder="Enter password"
                            :class="{'is-invalid':$v.new_password.$error}"
                            required
                          >
                          </b-form-input>
                          <div v-if="$v.new_password.$error" class="invalid-feedback" >
                              <span v-if="!$v.new_password.required">New Password is required.</span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-form-group
                          id="confirm_password-group"
                          label="Confirm Password"
                          class="mb-3"
                          label-for="confirm_password"
                        >
                          <b-form-input
                            id="confirm_password"
                            v-model="confirm_password"
                            type="password"
                            placeholder="Enter password"
                            required
                            :class="{'is-invalid':$v.confirm_password.$error}"
                          >
                          </b-form-input>
                          <div v-if="$v.confirm_password.$error" class="invalid-feedback" >
                              <span v-if="!$v.confirm_password.required">Confirm Password is required.</span>
                          </div>
                        </b-form-group>
                      </div>
                    <div class="col-md-12">
                      <SubmitButton :processing="processing" @clicked="updatePassword" text="Update Password"></SubmitButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        <!-- Nav tabs -->
        <!-- Tab content -->
      </div>

   
    <!-- Model -->
    <b-modal
      id="modal-minor"
      size="lg"
      title="Minor Attachment"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 border">
          <iframe
            v-if="teamMember.employee.under_eighteen_docs != null"
            :style="{ height: '500px' }"
            width="100%"
            :src="assets_url + teamMember.employee.under_eighteen_docs"
          >
          </iframe>
          <div v-else class="f-14 roboto align-middle">
            No Attachment Added
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
<style scoped>
.my-form .vue-avatar-cropper-demo .card-body{
 z-index: 2;
}
.my-form .vue-avatar-cropper-demo {
    height: 250px;
    width: 250px;
    position: relative;
}
.my-form #pick-avatar{
  position: absolute;
  height:100%;
  width: 100%;
  background: transparent;
  color: #222;
  z-index: 3;
    border: 1px solid #f251b0 !important;
}
.my-form #pick-avatar span{
 
  z-index: 0;

}

.my-form button#pick-avatar:before {
    position: absolute;
    content: '\ea3d';
    font-family: unicons-line;
    font-size: 18px;
    top: 0px;
    z-index: 999;
    width: 20px;
    height: 20px;
    right: 2px;
} 
</style>